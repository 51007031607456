
import { defineComponent, ref, useContext } from '@nuxtjs/composition-api'
import { LanguageType } from '~/constants/language'
import { toTranslatedTextNoUseCache } from '~/components/logics/translate/translate'

const ContentsRatingComment = defineComponent({
  name: 'ContentsRatingComment',
  components: {
    DLoading: () => import('~/components/atoms/DS2/DLoading'),
    DTranslationExecution: () => import('~/components/molecules/DS2/DTranslationExecution')
  },
  props: {
    comment: {
      type: String,
      require: true
    }
  },
  setup(props) {
    const { $translate } = useContext()
    const isTranslated = ref(false)
    const translatedMessage = ref('')
    const isTranslateStart = ref(false)
    const language = ref<LanguageType>($translate.getLanguageCode())

    const handleTranslate = async () => {
      isTranslated.value = !isTranslated.value
      if (translatedMessage.value !== '' || !props.comment) return
      isTranslateStart.value = true
      translatedMessage.value = await toTranslatedTextNoUseCache(props.comment, language.value)
      isTranslateStart.value = false
    }

    return {
      isTranslateStart,
      isTranslated,
      translatedMessage,
      handleTranslate,
      showTranslateButton: $translate.isTranslatableTarget()
    }
  }
})
export default ContentsRatingComment
